import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'

export const loyalty = (globalCustomProps) => {
  // --------------------------------------
  // Define layout spas
  // --------------------------------------
  const { featureFlags } = globalCustomProps || {}
  const LOYALTY_ACCOUNT_PAGE_V2 = featureFlags?.['loy-tracking-page-v2']
  const SPA_NAME = LOYALTY_ACCOUNT_PAGE_V2
    ? 'loyalty-account-page-spa'
    : 'loyalty-web'

  registerApplication({
    name: SPA_NAME,
    activeWhen: ({ pathname }) =>
      Boolean(routeMatcher(['/loyalty/welcome/*'])(pathname)),
    app: importSpa(SPA_NAME)
  })
}
