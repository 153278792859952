import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'focus-visible'

import { addErrorHandler, start } from 'single-spa'
import { account } from '../registration/account'
import { curbsideArrival } from '../registration/curbside-arrival'
import { fundraising } from '../registration/fundraising'
import { invoice } from '../registration/invoice'
import { loyalty } from '../registration/loyalty'
import { devTools } from '../registration/dev-tools'
import { financing } from '../registration/financing'
import { guestFeedback } from '../registration/guestFeedback'
import { preLoginOnboarding } from '../registration/preLoginOnboarding'
import { /*setupSentry*/ handleError, setupSentry } from '../utils'
import { getGlobalCustomProps } from '@local/global-custom-props'
import { loyaltySignUp } from '../registration/loyaltySignUp'
import { phygital } from '../registration/phygital'
import { urlShortener } from '../registration/urlShortener'
import { insightsPublicPage } from '../registration/insightsPublicPage'
import { singleSignOn } from '../registration/singleSignOn'

setupSentry({
  publicKey: '3574fb78dc79434e96dc992c9731bea1@o37442',
  projectId: '6479046'
})

const globalCustomProps = getGlobalCustomProps()

devTools()
account(globalCustomProps)
singleSignOn()
curbsideArrival()
fundraising()
invoice()
insightsPublicPage()
loyalty(globalCustomProps)
loyaltySignUp()
financing()
guestFeedback()
preLoginOnboarding()
phygital()
urlShortener()
start({
  urlRerouteOnly: true
})
addErrorHandler(handleError)
